<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="config. condições" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">

                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <b-tabs>
                                  
                                    <b-tab class="tab-pane" id="terms">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-frown font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Termos de uso</span>
                                            </div>
                                        </template>
                                            
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Título do texto</label>
                                                        <input type="text" class="form-control" rows="7" v-model="lp.title" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>

                                    <b-tab class="tab-pane" id="privacy">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-envelope-open font-20 align-middle mr-2"></i><span class="d-none d-lg-block">Política de privacidade</span>
                                            </div>
                                        </template>

                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Texto</label>
                                                            <textarea class="form-control" rows="7" v-model="lp.text" placeholder=""></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </b-tab>
                                   
                                </b-tabs>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="submit" class="btn btn-info">Salvar</button>
                                    </div>
                                </div>
                            </form>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import  ThemeService from "@/services/resources/ThemeService";
const service = ThemeService.build();

export default {
  data() {
    return {
      lp: {
          id: 0,
          title: null,
          text: null,
      },
      pages:[
            {
                name: 'dashboard',
                to: 'Dashboard'
            },
           
      ],
      menu:[],
    };
  },
  methods: {
    save(){
          

                    let data = {
                        id: 0,
                        theme: [
                        {
                            key: 'BROKER_LP_TITLE',
                            value: this.lp.title
                        },
                        {
                            key: 'BROKER_LP_TEXT',
                            value: this.lp.text
                        }]
                    }

     

            service
            .update(data)
            .then(resp => {
                 this.$bvToast.toast('Atualizado com sucesso!', {
                    title: 'Landing page',
                    autoHideDelay: 5000,
                    type: 'success'
                })
            })
            .catch(err => {
                console.log(err)
            })
          
  
    },
    fetch(){
         let data = {
                domain: window.location.hostname.replace('app.', '')
            }; 
        service
        .search(data)
        .then(resp => {
            if(resp){
            resp.forEach(ele => {
                if(ele.key=='BROKER_LP_TITLE'){
                    this.lp.title = ele.value
                }
                if(ele.key=='BROKER_LP_TEXT'){
                    this.lp.text =  ele.value
                }
            });
               
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

  },
  mounted() {
      this.fetch();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>